// services/StripeService.js

import Logger from "../common/Logger";
import AbstractService from "./AbstractService";
import Services from "./Services";

export class StripeService extends AbstractService {
  constructor() {
    super();
    this.logger = new Logger("StripeService");
  }

  async fetch(endpoint, method, body) {
    const response = await fetch(Services.getConfig().proxy_URL + endpoint, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
      const errorJson = await response.json().catch(() => ({}));
      throw new Error(errorJson.error || errorJson.message || "Request failed");
    }

    return response.json();
  }

  findAllFlowrabbitPlans() {
    return this._get("/rest/subscriptions/plans.json");
  }

  async getStripeProducts() {
    const response = await this.fetch("/stripe/org/plans", "GET");
    return response.products;
  }

  async getSubscriptionByOrgId(orgId) {
    return this.fetch("/stripe/org/subscription", "POST", { orgId });
  }

  async createUpdateSubscription({ orgId, priceId, billingAddress, paymentMethodId, userEmail, planId }) {
    return this.fetch("/stripe/org/subscribe", "POST", {
      orgId,
      priceId,
      billingAddress,
      paymentMethodId,
      planId,
      userEmail,
    });
  }

  async reactivateSubscription(orgId) {
    return this.fetch("/stripe/org/subscriptions/reactivate", "POST", { orgId });
  }

  async cancelSubscription(orgId) {
    return this.fetch("/stripe/org/subscriptions/cancel", "POST", { orgId });
  }

  async updateSubscription(data) {
    const { orgId, newPriceId, paymentMethodId, billingAddress, userEmail, planId } = data;
    return this.fetch("/stripe/org/subscriptions/update", "POST", {
      orgId,
      newPriceId,
      paymentMethodId,
      billingAddress,
      userEmail,
      planId,
    });
  }

  async createPaymentIntentOrg(data) {
    const { orgId, amount, email, userId, billingAddress } = data;
    return this.fetch("/stripe/org/paymentIntent", "POST", {
      orgId,
      amount,
      email,
      billingAddress,
      userId,
    });
  }

  async getCustomerDetails(orgId) {
    const response = await this.fetch("/stripe/org/customer/details", "POST", { orgId });
    return response;
  }

  async createSetupIntent(data) {
    const { orgId, email } = data;
    return this.fetch("/stripe/org/setupIntent", "POST", {
      orgId,
      email,
    });
  }

  async updateCustomerAddress(addressData) {
    return this.fetch("/stripe/org/updateCustomerAddress", "POST", addressData);
  }

  async updatePaymentMethod(data) {
    return this.fetch("/stripe/org/updatePaymentMethod", "POST", data);
  }

  async getSelectedPlan(orgID) {
    const plans = await this.findAllFlowrabbitPlans();
    const planPayAsGo = plans.find((p) => p.defaultPlan);
    let selectedPlan = planPayAsGo;
    const stripeProducts = await this.getStripeProducts();

    const allPlans = plans.map((plan) => {
      const product = stripeProducts.find((p) => p.id === plan.stripeProductID);
      return { ...plan, ...product, description: plan.description };
    });
    const subResponse = await this.getSubscriptionByOrgId(orgID);
    if (subResponse?.subscription?.id) {
      const timestamp = subResponse.subscription?.current_period_end;
      const future = timestamp > Math.floor(Date.now() / 1000);
      const subscriptionPlan = subResponse.subscription;
      const planData = allPlans.find((plan) => plan.stripeProductID === subscriptionPlan.plan.product);
      selectedPlan = { ...subscriptionPlan, ...planData };
      if (!future && this.planCancelled) {
        selectedPlan = planPayAsGo;
      }
    }
    return selectedPlan;
  }
}

export default new StripeService();
